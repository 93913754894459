import { Component, OnInit } from '@angular/core';

import { ConfigService } from '../../core/services/config.service';

import { MenuContentModel } from '../models/main-navigation-content.model';

/**
 * Main Navigation Component
 */
@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent implements OnInit {
  /**
   * Holds the menu configuration
   */
  public menu: MenuContentModel[] = [];
  /**
   * Holds the static contents of main navigation component
   */
  public mainNavigationContents: Record<string, any>;

  /**
   * The constructor method
   * @param configService config service
   */
  constructor(private readonly configService: ConfigService) {
    // Load this component's static contents from app-contents.json file
    this.mainNavigationContents = this.configService.getContents('mainNavigation');
  }

  /**
   * The ngOnInit method.
   */
  public ngOnInit(): void {
    // holds the menu configuration
    this.menu = this.mainNavigationContents.menu;
  }

  public navigateToRoute(path: string) {
    // Below flag is required to keep track of which route is triggered when user is having unsaved audience changes
    // When click on Save Button from Audience Settings popup window (with unsaved warning message), redirecting to below route.
    if (sessionStorage.getItem('save_audience') === 'true') {
      sessionStorage.setItem('next_state', path);
    }
  }

}
